@import './Config';
@import "~bootstrap/scss/bootstrap";

body{
    font-family: 'Poppins', sans-serif;
}
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5{
    font-weight: 600;
}

.text-theme{
    color: $theme-color;
}
.text-blue{
    color: $theme-color-blue;
}

/* Buttons */
.btn{
    border: none;
    padding: 0.634rem 2rem;
    border-radius: 0.75rem;
    font-weight: 500;
    &.btn-grad {
        background-image: linear-gradient(273.99deg, $theme-color-blue 30%, $theme-color 100%, rgba(213, 33, 46, 0) 100%);
        background-size: 100% auto;
        background-position: center center;
        color: #fff;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
        &:hover, &:focus{
            color: #fff;
            background-size: 250% auto;
            background-position: left center;
        }
    }
    &.btn-white{
        background-color: #fff;
        color: $theme-color-blue;
        box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.25);
        &:hover, &:focus{
            background-color: #fff;
            color: $theme-color-blue;
        }
    }
}

/* Forms */
.form-group{
    display: block;
    margin: 0 0 1rem 0;
}
.form-control{
    font-size: 1.125rem;
    border: 2px solid #dddddd;
    border-radius: 0.75rem;
    padding: 0.625rem 1rem;
    border-width: 2px;
    &:focus{
        border-color: $theme-color-blue;
        box-shadow: 0 0 0 0.2rem rgb(45 51 101 / 20%);
    }
}
.has-error{
    .form-control{
        border-color: #be2e2e;
        box-shadow: 0 0 0 0.25rem rgb(248 180 180 / 50%);
    }
}

/* Sections */
section{
    display: block;
    clear: both;
    position: relative;
    padding: 60px 0;
    overflow: hidden;
    background-color: #fff;
    &.section-light{
        background-color: #F1F1F1;
    }
    &.section-light-x{
        background-color: #e8e8e8;
    }
    .section-title{
        margin: 0 0 15px;
        position: relative;
        .lead{
            font-size: 1rem;
            display: block;
            color: #525252;
        }
    }
}

.hero-small {
  
   background: linear-gradient(0deg, rgb(245 245 245), rgb(255 255 255), rgb(255 255 255 / 90%));

   position: relative;
   width: 100%;
   /*height: 350px;*/
   /*min-height: calc(75vh - 120px);*/
//    background-attachment: unset!important;
   padding: 60px 0;
//    background-size: 100%!important;
//    background-position: top center !important;
//    background-repeat: no-repeat;
}

.section-contact{
    h3{
        margin-top: 30px;
    }
    h4{
        margin-top: 15px;
        font-size: 1.2rem;
    }
}