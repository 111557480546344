@import '../Config';

.site-header{
    position: relative;
    display: block;
    width: 100%;
    min-height: 70px;
    background-color: #fff;
    @media only screen and (max-width: 991px){
        min-height: 64px;
    }
    .container{
        position: relative;
    }
    .navbar{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        min-height: 70px;
        padding: .8rem 0;
        display: block;
        z-index: 99;
        background-color: #ffffffbd;
        backdrop-filter: blur(10px);
        box-shadow: 0 4px 2px -2px rgba(0,0,0,.2);
        @media only screen and (max-width: 991px){
            padding: 1rem 0;
            min-height: 64px;
        }
    }
    .navbar-brand{
        padding: 0;
        img{
            max-width: 300px;
            @media only screen and (max-width: 991px){
                max-width: 200px;
            }
        }
    }
}
/* .translated-ltr{
    .site-header {
        .navbar{
            top: 40px;
        }
    }
} */
.navbar-toggler{
    position: absolute;
    right: .5rem;
    border: 0px;
    padding: 0;
    &:focus{
        box-shadow: none;
    }
    .navbar-toggler-icon{
        width: 2em;
    }
}
.navbar-offcanvas{
    .navbar-nav{
        .nav-link{
            padding: 0 1rem;
            font-size: 1rem;
            font-weight: 600;
            color: #000;
            text-transform: capitalize;
            &:hover,
            &:focus,
            &.active{
                color:$theme-color;
            }
            @media only screen and (max-width: 991px){
                padding: .8rem 1.2rem;
                border-bottom: 1px solid #eee;
            }
        }
    }
    .offcanvas-header{
        background-color: #f4f4f4;
        min-height: 64px;
        img{
            max-width: 200px;
        }
        .btn-close{
            width: 1.3em;
            background-size: 1.3em;
            &:focus{
                box-shadow: none;
            }
        }
        .close-icon{
            width: 20px;
            position: absolute;
            right: 20px;
            padding-top: 5px;
        }
    }
    .offcanvas-body{
        padding: 1rem 0;
    }
}