@import '../Config';

/* Section hero */
.section-hero{
    display: table;
    table-layout: fixed;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 500px;
    min-height: calc(100vh - 70px);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #F1F1F1;
    color: #000;
    padding: 4rem 0;
    z-index: 1;
    .hero-image{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        color: #fff;
        background-color: #F1F1F1;
        z-index: -1;
        &:before:not(.no-overlay){
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: linear-gradient(to left, transparent, #B73336);
            z-index: -1;
        }
        &.hero-home{
            background-position: center bottom;
        }
    }
    .hero-title{
        font-size: 2.3rem;
        margin: 0 0 1rem 0;
        @media only screen and (max-width: 991px){
            font-size: 2rem;
        }
    }
    .hero-lead{
        margin: 0 0 1rem 0;
    }
    .hero-downloads{
        img{
            width: auto;
            height: auto;
            max-width: 32px;
            margin: 0 .5rem;
        }
    }
}

/* Section about */
.section-about{
    .about-image{
        display: block;
        position: relative;
        max-width: 90%;
        margin: 0 auto 2rem auto;
        z-index: 1;
        @media only screen and (max-width: 991px){
            margin: 0 auto 4rem 1.5rem;
            max-width: 100%;
        }
        &:before{
            content: "";
            position: absolute;
            left: -1.5rem;
            top: 1.5rem;
            right: 0;
            bottom: -1.5rem;
            background-color: $theme-color-blue;
            width: 100%;
            border-radius: 1rem;
            box-shadow: -10px 10px 37px rgba(0, 0, 0, 0.25);
            z-index: -1;
        }
        img{
            filter: drop-shadow(4px -6px 30px rgba(0, 0, 0, 0.25));
        }
    }
}

/* Section features */
.section-features{
    .features-item{
        margin-bottom: 2rem;
        img{
            width: 100%;
            height: auto;
            max-width: 54px;
        }
        h3{
            font-size: 1.2rem;
        }
        p:last-child{
            margin: 0;
        }
    }
}

/* Section contact */
.form-contact{
    background: #B9BAC6;
    padding: 10%;
    border-radius: 0.75rem;
}

