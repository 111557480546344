@import '../Config';

.switch {
    .form-check-input {
        width: 1.5em;
        height: 1.5em;
    }

    .form-check {
        min-height: 2rem;
    }

    .form-check-input:checked {
        background-color: #4caf50;
        border-color: #4caf50;
    }

    .form-check-label {
        margin-left: 15px;
        vertical-align: middle;
        line-height: 2;
    }

}

.form-control,
.form-select {
    font-size: 1.125rem;
    border: 1px solid #dddddd;
    border-radius: 1rem;
    padding: 0.5rem 0.9rem;
    border-width: 0.5px;
}


.form-control::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #adb5bd;
    opacity: 1;
    /* Firefox */
}

.date-header {
    padding: 10px;
    background: $theme-color;

    p {
        color: #ffffff;
    }

    h4 {
        color: #ffffff;
        margin-bottom: 0;
        font-weight: 400;

        .dy-name {
            font-weight: 300;
        }

        .dy-month {
            font-weight: 200;
        }

        .dy-day {
            font-weight: 600;
        }
    }
}

.rasta-stripes {
    border: none !important;

    .react-datepicker__triangle {
        display: none;
    }

    .react-datepicker__header {
        background: #ffffff;

        .react-datepicker__current-month {
            color: rgb(0, 0, 0);
        }

        .react-datepicker__year-select,
        .react-datepicker__month-select {
            font-size: 0.9rem;
            border: none;
            border-radius: 1rem;
            padding: 0.1rem 1.3rem;
            border-width: 0.5px;
            width: 100px;
            color: $theme-color;
            margin: 5px 0px;
            background: rgb(255, 233, 233);
        }

    }

    .react-datepicker__navigation-icon {
        top: 3px;
    }

    .react-datepicker__navigation-icon::before {
        border-color: rgb(0, 0, 0);
    }

    .react-datepicker {
        border: 1px solid $theme-color;

    }

    .react-datepicker__day--selected,
    .react-datepicker__day--keyboard-selected {
        border-radius: 1.3rem;
        background-color: #ffffff;
        color: #000;
        border: 2px solid $theme-color;
    }

    .react-datepicker__day--selected:hover {
        border-radius: 1.3rem;
        background-color: #ffffff;
    }

    .react-datepicker__day:hover {
        border-radius: 1.3rem;
        background-color: $theme-color;
        color: white;
    }

    .react-datepicker__day--today {
        color: $theme-color;
    }

    .react-datepicker__day {
        width: 2rem;
        height: 2rem;
        line-height: 1.7rem;
    }

    .react-datepicker__day-name {
        color: rgb(0, 0, 0);
    }
}

section#register {
    overflow: initial;
}

.err-tag {
    margin: 5px;
    color: $theme-color;
    font-size: 14px;
}

.error-bdr {
    border: 1px solid $theme-color;
}

.form-control:focus,
.form-select:focus {
    border-color: #2d336500;
    box-shadow: 0 0 0 0.2rem rgb(45 51 101 / 0%);
}

.img-header-blue {
    background-color: $theme-color-blue;
    height: 10px;
}

.img-header-red {
    background-color: $theme-color;
    height: 20px;
}

.user-id {
    background: white;
    max-width: 450px;
    // height: 450px;
    position: relative;

    .title {
        color: $theme-color-blue;
        margin: 0;
        font-size: 14px;
    }

    .sub-title {
        color: $theme-color;
        margin: 0;
        font-size: 12px;
    }

    .lang {
        font-size: 13px;
        margin-top: 4px;
        margin-bottom: 11px;
    }

    .user-data {
        p {
            font-size: 14px;
            margin-bottom: 0px;
            font-weight: 600;
            letter-spacing: 1px;
        }
    }

    .year {
        transform: rotate(-90deg);
        position: absolute;
        top: 50%;
        right: -74px;
        font-size: 90px;
        font-weight: 800;
        line-height: 0;
        color: #b7333626;
    }
}

.loader-sec{
    background: white;
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 9999;
}

.dna-wrapper{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform: -webkit-translate(-50%, -50%);
    transform: -moz-translate(-50%, -50%);
    transform: -ms-translate(-50%, -50%);
    color: darkred;
    z-index: 9999; 
}


.custom-btn{
    background: $theme-color;
    color: white;
}

.link-btn{
    color: $theme-color;
}

.text-theme-color{
    color: $theme-color;
}

.circle{
   width: 100px;
   height: 100px;
   border-radius: 50%;
   overflow: hidden;
   display: block;
   margin: 0 auto;
}
.circle img{
   width: 100%;
   height: 100%;
   object-fit: cover;
   object-position: center center;
}
.register-upload{
    background: #f9f9f9;
    padding: 15px;
    border-radius: 1rem;
    text-align: center;
}

.postive{
    // position: absolute;
    // left: 0;
    // right: 31%;
    // top: 18px;
    font-size: 65px;
    color: $theme-color-blue;
    // margin: 0 auto;
    margin-left: -160px;
    margin-bottom: -130px;
}

.postive1{
    // position: absolute;
    // left: 0;
    // right: 31%;
    // top: 18px;
    font-size: 65px;
    color: $theme-color-blue;
    // margin: 0 auto;
    
}

.img-card{
    position: relative;
}