@import '../Config';

.site-footer {
    position: relative;
    display: block;
    clear: both;
    padding: 30px 0 0 0;
    background-color: #ffffff;
    border-top: 5px solid #2d3365;

    h3{
        display: inline-block;
        text-transform: uppercase;
        font-size: 1rem;
        margin: 30px 0 20px;
        border-bottom: 2px solid #b32023;
        color: #12195e;
    }

    ul{
        list-style: none;
        padding: 0;
        margin: 0 0 1rem 0;
        list-style: none;
    }
    
    .footer-network {
        display: block;
        overflow: hidden;
        li {
            display: inline-block;
            margin: .5rem .75rem;

            img {
                width: 30px;
                height: auto;
            }
        }

        @media only screen and (max-width: 991px) {
            padding-top: 1rem;
            text-align: left;
        }
    }

    .playstore-btn {
        display: block;
        width: 100%;
        max-width: 200px;
    }

    .footer-copy {
        text-align: center;
        background: $theme-color-blue;
        margin-top: 40px;
        padding: 2px 0;
        color: white;
        font-size: 14px;
    }

    .footer-list{
        li{
            display: block;
            margin: 0 0 .4rem 0;
            a{
                color: #000;
                text-decoration: none;
                &:hover, &:focus{
                    color: #000;
                }
            }
        }
    }
}

/* Google translate */
body > .skiptranslate {
    display: none;
}
.goog-te-banner-frame.skiptranslate {
    display: none !important;
}
body {
    top: 0px !important;
}
.goog-tooltip {
    display: none !important;
 }
.goog-tooltip:hover {
    display: none !important;
}
.goog-text-highlight {
    background-color: transparent !important;
    border: none !important; 
    box-shadow: none !important;
}
@media print {
    #google_translate_element {
        display: none;
    }
}

.translate {
    position: fixed;
    bottom: 15px;
    right: 15px;
    width: 50px;
    height: 50px;
    background-color: #b32023;
    overflow: hidden;
    border-radius: 50%;
    display: -ms-grid;
    display: grid;
    place-content: center;
    cursor: pointer;
    -webkit-box-shadow: 0 0 12px rgb(0 0 0 / 8%);
    box-shadow: 0 0 12px rgb(0 0 0 / 8%);
    z-index: 100;
    i {
        color: #fff;
        font-size: 24px;
        width: 50px;
        line-height: 50px;
        text-align: center;
    }
    #google_translate_element {
        position: absolute;
        opacity: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
    }
    .goog-te-gadget {
        font-family: arial;
        font-size: 11px;
        color: #666;
        white-space: nowrap;
        .goog-te-combo{
            width: 100%;
            height: 50px;
            margin: 0;
            border: 0;
        }
    }
}

.support_links{
    display: flex;
    justify-content: space-around;
    margin: 10px;
    span{
        padding: 0 20px;
        cursor: pointer;
    }
}

.copy-write{
    background: #e8e8e8;
}

@media (max-width: 768px) {
    .support_links {
      flex-direction: column;
      align-items: center;
    }
  
    .support_links span {
      margin: 5px 0;
    }
  }
 
